import { Card, Spinner, Pagination, Dropdown, Table } from 'react-bootstrap';
import React, { useCallback, useMemo } from 'react';
import {
  Column,
  HeaderGroup,
  SortingRule,
  TableState,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useTranslation } from 'next-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { RootState } from '../../model/slices/root/root.state';
import { actions } from '../../model/slices/app/app.slice';

export interface ReactTableBlockFetchDataParams<T extends object = {}, F = any> {
  pageIndex: number;
  pageSize: number;
  sortBy: SortingRule<T>[];
  globalFilter: F | undefined;
}

export type ReactTableBlockGlobalFilterProps<F> = {
  value: F | undefined;
  setValue: (value: F | undefined) => void;
};

type ReactTableBlockProps<T extends object = {}, F = any> = {
  id?: string;
  title?: string | React.ReactNode;
  columns: Column<T>[];
  data: T[];
  getRowId: (row: T) => string;
  isLoading?: boolean;
  isFetching?: boolean;
  striped?: boolean;
  className?: string;
  total?: number;
  totalPages?: number;
  hidePagination?: boolean;
  hideFilterManagement?: boolean;
  emptyText?: string;
  fetchData?: (params: ReactTableBlockFetchDataParams<T, F>) => void;
  initialState?: Partial<TableState<T>>;
  GlobalFilterComponent?: React.FC<ReactTableBlockGlobalFilterProps<F>>;
};

const ReactTableBlock = <T extends {}, F = any>({
  id,
  title,
  columns,
  data,
  getRowId,
  isLoading,
  isFetching,
  striped = true,
  className,
  total,
  totalPages,
  hidePagination,
  hideFilterManagement,
  fetchData,
  initialState,
  emptyText,
  GlobalFilterComponent,
}: ReactTableBlockProps<T, F>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const defaultListFilters = useSelector((root: RootState) => root.app.defaultListFilters);
  const defaultFilter = id != null ? defaultListFilters[id] : undefined;

  const tableInitialState = {
    ...initialState,
    globalFilter: initialState?.globalFilter || defaultFilter,
  };

  const columnsWithSortDescFirst = useMemo(
    () => columns.map(column => ({ sortDescFirst: true, width: 'auto', ...column })),
    [columns]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, sortBy, globalFilter },
  } = useTable<T>(
    {
      columns: columnsWithSortDescFirst,
      data,
      getRowId,
      manualPagination: fetchData != null,
      manualGlobalFilter: fetchData != null,
      pageCount: totalPages,
      autoResetPage: fetchData == null,
      manualSortBy: fetchData != null,
      disableMultiSort: true,
      initialState: tableInitialState,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  React.useEffect(() => {
    if (fetchData) {
      fetchData({ pageIndex, pageSize, sortBy, globalFilter });
    }
  }, [fetchData, pageIndex, pageSize, sortBy, globalFilter]);

  React.useEffect(() => {
    if (globalFilter) {
      return () => {
        gotoPage(0);
      };
    }
    return undefined;
  }, [globalFilter, gotoPage]);

  React.useEffect(() => {
    if (totalPages != null && pageIndex >= totalPages) {
      gotoPage(0);
    }
  }, [totalPages, pageIndex, gotoPage]);

  const saveGlobalFilterValues = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (id) {
        toastr.success('', t('TABLE_BLOCK.DEFAULT_FILTER.SAVED'));
        dispatch(actions.setDefaultListFilters({ ...defaultListFilters, [id]: globalFilter }));
      }
    },
    [defaultListFilters, dispatch, globalFilter, id, t]
  );

  const clearGlobalFilterValues = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setGlobalFilter(undefined);
    },
    [setGlobalFilter]
  );

  const renderSortingArrows = (column: HeaderGroup<T>) => {
    if (column.disableSortBy) {
      return null;
    }
    if (!column.isSorted) {
      return (
        <span className="ms-1">
          <img src="/img/icons/arr-down-black.svg" style={{ transform: 'rotate(180deg)' }} />
          <img src="/img/icons/arr-down-black.svg" />
        </span>
      );
    }
    return column.isSortedDesc ? (
      <img className="ms-1" src="/img/icons/arr-down-black.svg" />
    ) : (
      <img
        className="ms-1"
        src="/img/icons/arr-down-black.svg"
        style={{ transform: 'rotate(180deg)' }}
      />
    );
  };

  const getColumnWidthStyle = (width: string | number | undefined) => ({
    width,
    minWidth: width || 'auto',
    maxWidth: width || 'none',
  });

  return (
    <>
      {GlobalFilterComponent && (
        <>
          {!hideFilterManagement && (
            <div className="d-flex mb-2">
              <a href="#" onClick={saveGlobalFilterValues}>
                {t('TABLE_BLOCK.SAVE_AS_DEFAULT_FILTER')}
              </a>
              <a className="ms-2 text-danger" href="#" onClick={clearGlobalFilterValues}>
                {t('TABLE_BLOCK.CLEAR_FILTER')}
              </a>
            </div>
          )}
          <GlobalFilterComponent value={globalFilter} setValue={setGlobalFilter} />
        </>
      )}
      <Card className={className || ''}>
        {title && (
          <Card.Header>
            <Card.Title className="mb-0">{title}</Card.Title>
          </Card.Header>
        )}
        <Table striped={striped} responsive {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      whiteSpace: 'nowrap',
                      backgroundColor: 'transparent',
                      cursor: column.disableSortBy ? 'default' : 'pointer',
                      ...getColumnWidthStyle(column.width),
                    }}
                    scope="col"
                  >
                    {column.render('Header')}
                    {renderSortingArrows(column)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                // eslint-disable-next-line react/jsx-key
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    const cellProps = cell.getCellProps();
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        {...cellProps}
                        style={{ ...cellProps.style, ...getColumnWidthStyle(cell.column.width) }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {data.length === 0 && (
          <div className={`react-table__empty ${isLoading && 'react-table__empty--loading'}`}>
            {t(emptyText || 'TABLE_BLOCK.EMPTY.TEXT')}
          </div>
        )}
        {!hidePagination && (
          <div className="react-table__pagination">
            <div style={{ flex: 1 }}>
              {t('TABLE_BLOCK.PAGINATION.TOTAL_COUNT', { value: total || data.length })}
            </div>
            {isFetching && !isLoading && (
              <Spinner animation="border" variant="dark" size="sm" className="me-2" />
            )}
            <span className="me-3">
              {t('TABLE_BLOCK.PAGINATION.CURRENT_PAGE', {
                current: pageIndex + 1,
                total: Math.max(1, pageOptions.length),
              })}
            </span>
            <Pagination size="sm" className="m-0">
              <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
              <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
              <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
              <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
            </Pagination>
            <Dropdown align="end" drop="down" className="ms-3">
              <Dropdown.Toggle size="sm" variant="secondary">
                {t('TABLE_BLOCK.PAGINATION.PAGE_SIZE', { value: pageSize })}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[10, 20, 30].map(s => {
                  return (
                    <Dropdown.Item
                      key={s}
                      onClick={e => {
                        e.preventDefault();
                        setPageSize(s);
                      }}
                    >
                      {s}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        {isLoading && (
          <div
            className="position-absolute w-100 h-100"
            style={{ backgroundColor: '#ffffff', opacity: 0.4 }}
          >
            <Spinner
              animation="border"
              variant="dark"
              className="me-2 position-absolute"
              style={{ top: '50%', left: '50%' }}
            />
          </div>
        )}
      </Card>
    </>
  );
};
export default ReactTableBlock;
